import Container from '@components/ui/Container/Container'
import Text from '@components/ui/Text/Text'
import Image from '@components/ui/Image'
import useI18n from '@utils/hooks/useI18n'
import { send404GTMEvent } from '@utils/helpers/gtm'
import { useEffect } from 'react'
import { NextSeo } from 'next-seo'
import MPBBlockDynamic from '@components/MagentoPageBuilder/blocks/MPBBlockDynamic'
import Icon from '@components/ui/Icon/Icon'

const Custom404Component = () => {
  const i18n = useI18n()
  useEffect(() => {
    send404GTMEvent()
  }, [])

  return (
    <>
      <NextSeo title="404 Page Not Found" noindex={true} nofollow={true} />

      <Container className="my-12 flex flex-col items-center justify-center">
        <div className="flex items-start justify-center">
          <Text
            variant="body"
            className="text-[160px] font-bold leading-[160px]"
          >
            4
          </Text>
          <Image
            src="/404.png"
            alt="404"
            width={150}
            height={150}
            className="mx-2"
          />
          <Text
            variant="body"
            className="text-[160px] font-bold leading-[160px]"
          >
            4
          </Text>
        </div>
        <div className="flex flex-col items-center justify-center">
          <Text
            variant="heading"
            className="mt-5 max-w-[350px] text-center text-xl font-bold"
          >
            {i18n.t([
              'Unfortunately we could not find the page you were looking for.',
            ])}
          </Text>
          <Text
            variant="paragraph"
            className="mt-5 max-w-[700px] text-center text-sm font-semibold"
          >
            {i18n.t([
              'We are constantly improving our webshop to offer you a quick and easy shopping experience. If your desired destination does not work, please try again in a few minutes.',
            ])}
          </Text>
        </div>
      </Container>
      <div className="my-4 w-full bg-[#4B515A] py-6">
        <Container className="flex flex-col items-center justify-center text-white">
          <Text variant="sectionHeading" className="text-3xl font-bold">
            {i18n.t(['You have questions?'])}
          </Text>
          <Text variant="paragraph" className="mb-2 text-sm">
            {i18n.t(['We would be pleased to help you by phone.'])}
          </Text>
          <div className="mb-2 flex items-center text-xl font-bold">
            <div className="-mr-2 flex aspect-square size-8 items-center justify-center rounded-full bg-white">
              <Icon icon="icon-phone" className="text-base text-[#4B515A]" />
            </div>
            <MPBBlockDynamic identifier="header-phone" />
          </div>
          <div className="flex items-center">
            <div className="-mr-2 flex aspect-square size-8 items-center justify-center rounded-full bg-white">
              <Icon icon="icon-mail" className="text-base text-[#4B515A]" />
            </div>
            <MPBBlockDynamic identifier="header-mail" />
          </div>
        </Container>
      </div>
    </>
  )
}

export default Custom404Component
