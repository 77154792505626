import { sendGTMEvent } from '@next/third-parties/google'
import googleAnalyticsProducts from '@utils/helpers/googleAnalyticsProducts'
import { store } from '@features/store'

export const sendProductGTMEvent = (source, breadcrumbs, country, currency) => {
  sendGTMEvent({
    event: 'view_item',
    ecommerce: {
      currency,
      value: source.storePrice.price,
      items: googleAnalyticsProducts(
        [{ _source: source }],
        breadcrumbs,
        0,
        country,
      ),
    },
  })
}

export const sendListGTMEvent = (
  items,
  breadcrumbs,
  country,
  currency,
  listName,
  pageSize = 0,
) => {
  sendGTMEvent({
    event: 'view_item_list',
    ecommerce: {
      currency,
      item_list_name: listName,
      items: googleAnalyticsProducts(
        items,
        listName !== 'accessories' && listName !== 'spare_parts'
          ? breadcrumbs
          : [],
        pageSize,
        country,
      ),
    },
  })
}

export const sendCategoryListGTMEvent = (children) => {
  const categoryList = []
  let categoryIndex = 0
  const isMobile = store.getState().ui.isMobile || false
  children
    ?.sort((a, b) => {
      const aPosition =
        isMobile && a.rank_order_mobile
          ? a.rank_order_mobile
          : a.rank_order_shop
            ? a.rank_order_shop
            : a.position
      const bPosition =
        isMobile && b.rank_order_mobile
          ? b.rank_order_mobile
          : b.rank_order_shop
            ? b.rank_order_shop
            : b.position
      return aPosition - bPosition
    })
    .forEach((category) => {
      categoryList.push({
        id: category.id,
        name: category.name,
        url: category.url_path,
        index: categoryIndex,
      })
      categoryIndex++
      category.children_data
        ?.sort((a, b) => {
          const aPosition =
            isMobile && a.rank_order_mobile
              ? a.rank_order_mobile
              : a.rank_order_shop
                ? a.rank_order_shop
                : a.position
          const bPosition =
            isMobile && b.rank_order_mobile
              ? b.rank_order_mobile
              : b.rank_order_shop
                ? b.rank_order_shop
                : b.position
          return aPosition - bPosition
        })
        .forEach((subCategory) => {
          categoryList.push({
            id: subCategory.id,
            name: subCategory.name,
            url: subCategory.url_path,
            index: categoryIndex,
          })
          categoryIndex++
        })
    })

  sendGTMEvent({
    event: 'view_category_list',
    categories: categoryList,
  })
}

export const send404GTMEvent = () => {
  sendGTMEvent({
    event: 'page_view',
    content_group: 'not found',
  })
}
